import React from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import SubPageImgHero from '../../components/subPageImgHero'
import { Layout } from '../../components/styled/layout'
import { isMobile } from 'react-device-detect'

import Img1 from '../../images/ReparasjonTilhengere/valeryd-hengerdeler_2.jpg'
import Img2 from '../../images/ReparasjonTilhengere/valeryd-hengerdeler_3.jpg'
import Img3 from '../../images/ReparasjonTilhengere/valeryd-hengerdeler_4.jpg'
import Img4 from '../../images/ReparasjonTilhengere/valeryd-hengerdeler_15.jpg'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'

const PageSection = !isMobile
  ? styled(Section)`
      display: flex;
      align-items: center;
      margin: 15px auto;
    `
  : styled(Section)`
      display: block;
      align-items: center;
      margin: 15px auto;
    `

const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  //adding new data
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

let LeftSection = !isMobile
  ? styled.div`
      max-width: 70%;
      margin-right: 6rem;
    `
  : styled.div`
      max-width: none;
      width: 100%;
      margin-right: 0;
    `
const RightSection = !isMobile
  ? styled.div`
      max-width: 30%;
      margin-top: -6rem;
      text-align: center;
    `
  : styled.div`
      max-width: none;
      width: 100%;
      margin-top: 0;
      text-align: center;
    `

const RepAvTilHengere = () => {
  const title = 'Reparasjon av tilhengere'
  const description1 = 'Tempo 100 – for deg som har tilhenger eller campingvogn  '
  const description2 =
    'Visste du at det nå er tillatt å trekke enkelte hengere og campingvogner opptil 100 kilometer i timen? Denne godkjenningen kalles Tempo 100, og utstedes av Statens Vegvesen. and the rest in the white area perhaps  '
  const Img = require('../../images/ReparasjonTilhengere/valeryd-hengerdeler_2.jpg')

  return (
    <Layout>
      <SubPageLayoutNoHero title={title}>
        <SEO title={title} />
        <SubPageImgHero
          title={title}
          description1={description1}
          description2={description2}
          Img={Img}
        ></SubPageImgHero>
        <PageSection>
          <LeftSection className="left-section">
            <Text>
              <h4>Tempo 100 – for deg som har tilhenger eller campingvogn</h4>
              <p>
                Visste du at det nå er tillatt å trekke enkelte hengere og campingvogner opptil 100
                kilometer i timen?
                <br />
                Denne godkjenningen kalles Tempo 100, og utstedes av Statens Vegvesen.
                <br />
                <br />
                Men har du en tilhenger eller campingvogn som er eldre enn fire år, må den ha en{' '}
                <b>godkjent EU-kontroll</b>,<br />
                før du kan bestille Tempo 100 hos Statens Vegvesen. EU-kontroll på gjennomføres
                hvert andre år på <br />
                Tempo 100-godkjente hengere og campingvogner, på samme måte som med personbiler.
                <br />
                <br />
                <b>
                  Vi i BilXtra Verksted utfører EU-kontroll også på hengere og campingvogner! Book
                  time her:{' '}
                </b>
                <a href="https://bilxtraverksted.no/">Bilxtraverksted.no</a>
              </p>

              <p>
                <br />
                <br />
                <b>Kan alle hengere få Tempo 100-godkjenning?</b>
                <br />
                <br />
                Det er noen krav som må oppfylles for at Tempo 100 skal kunne gis. Her er noen av
                kravene: <br />
                <ul>
                  <li>Bilen som trekker, må ha ABS-bremser.</li>
                  <li>Bilen må ha en tillatt totalvekt på 3.5 tonn eller lavere.</li>
                  <li>
                    Tilhengeren må ha stivt drag og aksling eller akslinger plassert i, eller i
                    nærheten av hengeren balansepunkt.
                  </li>
                  <li>Dekkene må være nyere enn seks år.</li>
                  <li>
                    I tillegg er det ifølge Statens Vegvesen, vektkrav til kombinasjon av bil og
                    henger. Dette for å unngå at trekkbilen ikke blir for lett i forhold til
                    trekkvekten.
                  </li>
                  <li>
                    Det er også krav til utstyr som sikrer stabil og trygg kjøring (stabilisator
                    kobling), og forsvarlige bremser og støtdempere på hengeren for å nevne noe.
                  </li>
                </ul>
                <br />
                Mer informasjon om krav finner du her:{' '}
                <a
                  href="https://www.vegvesen.no/kjoretoy/eie-og-vedlikeholde/tilhenger/tempo100/krav-til-trekkbil-og-tilhenger-for-tempo-100-godkjenning/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Krav til trekkbil og tilhenger for Tempo 100-godkjenning | Statens vegvesen.
                </a>
                <br />
                <br />
                Ønsker du å finne ut om din bil og din henger eller campingvogn kan bli godkjent for
                Tempo 100, kan du prøve Statens Vegvesen sin kalkulator her{' '}
                <a
                  href="https://www.vegvesen.no/kjoretoy/eie-og-vedlikeholde/tilhenger/tempo100/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Godkjenning av tilhenger for å kjøre i 100 km/t | Statens vegvesen
                </a>
                .
                <br />
                <br />
                Du er også velkommen til BilXtra verksted for reparasjon av alle typer tilhengere
                også de uten Tempo 100 godkjenning.
              </p>

              <ChooseRegion />
            </Text>
          </LeftSection>

          <RightSection className="right-section">
            <Text style={{ display: 'inline' }}>
              <p>
                <img alt="Reparasjon" style={{ width: '80%' }} src={Img1} />
                <br />
                <br />
                <img alt="Reparasjon" style={{ width: '80%' }} src={Img2} />
                <br />
                <br />
                <img alt="Reparasjon" style={{ width: '80%' }} src={Img3} />
                <br />
                <br />
                <img alt="Reparasjon" style={{ width: '80%' }} src={Img4} />
                <br />
                <br />
              </p>
            </Text>
          </RightSection>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}

export default RepAvTilHengere
